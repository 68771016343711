import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'

const description =
  'A casual raiding guild that has been around since 2004 — currently US-Faerlina. Our focus is to build and maintain a healthy supportive community that lasts.'

const Layout = ({ children, location }) => {
  let content

  if (location && location.pathname === '/') {
    content = <div>{children}</div>
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>{children}</div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet>
            <html lang="en" />
            <title>{data.site.siteMetadata.title}</title>
            <meta name="description" content={description} />
            <meta property="og:locale" content="en" />
            <meta
              property="og:site_name"
              content={data.site.siteMetadata.title}
            />
            <meta property="og:title" content={data.site.siteMetadata.title} />
            <meta property="og:type" content="website" />
            <meta property="og:description" content={description} />
            <meta
              property="og:image"
              content="https://hellscreamraiders.com/website-preview.png"
            />
            <meta property="og:image:alt" content="Website Preview" />
            <meta
              name="keywords"
              content="warcraft, wow, world of warcraft, hellscream, hellscream raiders, raiding, warrior, dps, recruitment, healer, meme, dad guild, recruiting, evexis, buffdanglin"
            />
          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
